import { Tooltip } from '@material-ui/core'
import moment from 'moment';
import React, { useEffect, useState } from 'react'

const ResponseTimeProgress = ({ DetectionTypeId, createdAt, takeActionList }) => {
  const [timeDiff, setTimeDiff] = useState({});
  const [currentProgress, setCurrentProgress] = useState(0);
  const [barColor, setBarColor] = useState("#4caf50");

  const responseDuration = (id) => {
    switch (id) {
      case 52:
        return { temporary: 1440, permanent: 4320 }
      case 55:
        return { temporary: 1440, permanent: 2880 }
      case 56:
        return { temporary: 0, permanent: 43200 }
      case 59:
        return { temporary: 0, permanent: 30 }
      case 60:
        return { temporary: 0, permanent: 30 }
      case 61:
        return { temporary: 0, permanent: 30 }
      // case 62:
      //   return {temporary:0, permanent:0}
      case 57:
        return { temporary: 720, permanent: 43200 }
      default:
        return { temporary: 0, permanent: 0 }
    }
  };

  const convertMinutes = (minutes) => {
    if (minutes >= 7200) { // 7200 minutes in 5 day
      return `${(minutes / 1440)}d`;
    } else if (minutes >= 60) { // 60 minutes in an hour
      return `${(minutes / 60)}h`;
    } else {
      return `${minutes}m`;
    }
  }

  const detectAt = moment(createdAt);
  const temperoryLimit = detectAt.clone().add(responseDuration(DetectionTypeId).temporary, 'minutes');
  const permanentLimit = detectAt.clone().add(responseDuration(DetectionTypeId).permanent, 'minutes');

  const differenceDetectPerm = permanentLimit.diff(detectAt, 'seconds');
  const differenceDetectTemp = temperoryLimit.diff(detectAt, 'seconds');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();

      const differenceDetectNow = now.diff(detectAt, 'seconds');
      // Calculate elapsed time between `detectAt` and `now`
      let elapsedDuration = moment.duration(now.diff(detectAt));

      if (differenceDetectNow >= differenceDetectPerm) {
        setCurrentProgress(100);
      } else {
        setCurrentProgress((differenceDetectNow) / differenceDetectPerm * 100);
      }

      // Calculate remaining time for temporary and permanent limits
      let durationTemp = moment.duration(temperoryLimit.diff(now));
      let overdueTemp = false;
      let durationPer = moment.duration(permanentLimit.diff(now));
      let overduePer = false;

      // Handle exceeded thresholds
      if (durationTemp.asMilliseconds() <= 0) {
        durationTemp = moment.duration(now.diff(temperoryLimit));
        overdueTemp = true;
      }
      if (durationPer.asMilliseconds() <= 0) {
        durationPer = moment.duration(now.diff(permanentLimit));
        overduePer = true;
      }

      if (takeActionList.find(e => e.ActionStatusId === 2)) {
        const time = moment(takeActionList.find(e => e.ActionStatusId === 2).takenAt);
        durationTemp = moment.duration(time.diff(detectAt));
      }

      if (takeActionList.find(e => e.ActionStatusId === 3)) {
        const time = moment(takeActionList.find(e => e.ActionStatusId === 3).takenAt);
        durationPer = moment.duration(time.diff(detectAt));
        elapsedDuration = moment.duration(detectAt.diff(detectAt));
        setCurrentProgress(0);
      }

      if (responseDuration(DetectionTypeId).permanent === 0) setCurrentProgress(0);

      setTimeDiff({
        elapsed: {
          days: Math.floor(elapsedDuration.asDays()),
          hours: elapsedDuration.hours(),
          minutes: elapsedDuration.minutes(),
          seconds: elapsedDuration.seconds(),
        },
        temporary: {
          completed: !!takeActionList.find(e => e.ActionStatusId === 2),
          overdue: overdueTemp,
          days: Math.floor(durationTemp.asDays()),
          hours: durationTemp.hours(),
          minutes: durationTemp.minutes(),
          seconds: durationTemp.seconds(),
        },
        permanent: {
          completed: !!takeActionList.find(e => e.ActionStatusId === 3),
          overdue: overduePer,
          days: Math.floor(durationPer.asDays()),
          hours: durationPer.hours(),
          minutes: durationPer.minutes(),
          seconds: durationPer.seconds(),
        },
      });

      setBarColor(
        currentProgress <= (differenceDetectTemp / differenceDetectPerm * 100)
          ? "#4caf50" // Green
          : differenceDetectNow < differenceDetectPerm
            ? "#ffa500" // Orange
            : "#f44336" // Red
      )
    }, 1000);

    return () => clearInterval(interval);
  }, [detectAt, takeActionList]);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          position: "relative",
          height: "5px",
          backgroundColor: "#ddd",
          borderRadius: "6px",
          overflow: "hidden",
          border: "1px solid grey",
        }}
      >
        <div
          style={{
            width: `${currentProgress}%`,
            height: "100%",
            backgroundColor: barColor,
            borderRadius: "6px",
          }}
        />
        {!!responseDuration(DetectionTypeId).temporary && (
          <Tooltip title={`Temporary Fix: ${convertMinutes(responseDuration(DetectionTypeId).temporary)} Response Time`}>
            <div
              style={{
                position: "absolute",
                left: `${differenceDetectTemp / differenceDetectPerm * 100}%`,
                top: "50%",
                transform: "translate(-50%, -50%)",
                width: "3px",
                height: "3px",
                backgroundColor: "#ffa500",
                borderRadius: "50%",
                border: "1px solid #fff",
              }}
            />
          </Tooltip>
        )}
        {!!responseDuration(DetectionTypeId).permanent && (
          <Tooltip title={`Permanent Fix: ${convertMinutes(responseDuration(DetectionTypeId).permanent)} Response Time`}>
            <div
              style={{
                position: "absolute",
                right: "0%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                width: "3px",
                height: "3px",
                backgroundColor: "#f44336",
                borderRadius: "50%",
                border: "1px solid #fff",
              }}
            />
          </Tooltip>
        )}
      </div>
      <p style={{ marginBottom: 0, marginTop: "10px", fontSize: "12px", color: "#555" }}>
        {`Elapsed time: `}
        <span style={{ color: '#0081B0' }}>
          {timeDiff.years ? `${timeDiff.years}y ` : ""}
          {timeDiff.months ? `${timeDiff.months}m ` : ""}
          {timeDiff?.elapsed?.days ? `${timeDiff.elapsed.days}d ` : ""}
          {timeDiff?.elapsed?.hours ? `${timeDiff.elapsed.hours}h ` : ""}
          {timeDiff?.elapsed?.minutes ? `${timeDiff.elapsed.minutes}m ` : ""}
          {timeDiff?.elapsed?.seconds ? `${timeDiff.elapsed.seconds}s ` : timeDiff?.permanent?.completed ? "-" : "0s"}
        </span>
      </p>
      {!!responseDuration(DetectionTypeId).temporary && (
        <p style={{ marginBottom: 0, fontSize: "12px", color: "#555" }}>
          {`
            ${timeDiff?.temporary?.completed ?
              "Completed for Temp. Fix after " :
              timeDiff?.temporary?.overdue ? "Overdue (Temp.) by " : "Due (Temp.) in: "} `}
          <span style={{ color: '#0081B0' }}>
            {timeDiff?.temporary?.days ? `${timeDiff.temporary.days}d ` : ""}
            {timeDiff?.temporary?.hours ? `${timeDiff.temporary.hours}h ` : ""}
            {timeDiff?.temporary?.minutes ? `${timeDiff.temporary.minutes}m ` : ""}
            {timeDiff?.temporary?.seconds ? `${timeDiff.temporary.seconds}s ` : "0s"}
          </span>
        </p>
      )}
      {!!responseDuration(DetectionTypeId).permanent && (
        <p style={{ marginBottom: 0, fontSize: "12px", color: "#555" }}>
          {`
            ${timeDiff?.permanent?.completed ?
              "Completed for Perm. Fix after " :
              timeDiff?.permanent?.overdue ? `Overdue ${!!responseDuration(DetectionTypeId).temporary ? '(Perm.)' : ''} by ` : `Due ${!!responseDuration(DetectionTypeId).temporary ? '(Perm.)' : ''} in: `}`}
          <span style={{ color: '#0081B0' }}>
            {timeDiff?.permanent?.days ? `${timeDiff.permanent.days}d ` : ""}
            {timeDiff?.permanent?.hours ? `${timeDiff.permanent.hours}h ` : ""}
            {timeDiff?.permanent?.minutes ? `${timeDiff.permanent.minutes}m ` : ""}
            {timeDiff?.permanent?.seconds ? `${timeDiff.permanent.seconds}s ` : "0s"}
          </span>
        </p>
      )}
    </div>
  )
}

export default ResponseTimeProgress;