import React from 'react';
import legendIcon from '@Assets/Icons/legendIcon.svg';
import YellowMarker from '@Assets/Icons/PointMarker/yellow_marker.svg';
import RedMarker from '@Assets/Icons/PointMarker/red_marker.svg';
import OrangeMarker from '@Assets/Icons/PointMarker/orange_marker.svg';
import GreenMarker from '@Assets/Icons/PointMarker/green_marker.svg';
import BlueMarker from '@Assets/Icons/PointMarker/blue_marker.svg';
import IBMarker from '@Assets/Icons/PointMarker/IB.svg';
import OBMarker from '@Assets/Icons/PointMarker/OB.svg';
import OPMarker from '@Assets/Icons/PointMarker/OP.svg';
import RBMarker from '@Assets/Icons/PointMarker/RB.svg';
import VDMarker from '@Assets/Icons/PointMarker/VD.svg';
import PBMarker from '@Assets/Icons/PointMarker/PB.svg';
import CBMarker from '@Assets/Icons/PointMarker/CB.svg';
import OBRMarker from '@Assets/Icons/PointMarker/OBR.svg';

const MapLegend = ({
  showLegend, setShowLegend, bridgeType, riskRanking,
}) => {
  const SlopeMarker = {
    1: RedMarker,
    2: OrangeMarker,
    3: YellowMarker,
    4: GreenMarker,
    5: BlueMarker,
    6: BlueMarker,
  };

  const BridgeMarker = {
    1: IBMarker,
    2: OBMarker,
    3: OPMarker,
    4: RBMarker,
    5: VDMarker,
    6: PBMarker,
    7: CBMarker,
    8: OBRMarker,
  };

  const styles = {
    legendContainer: {
      position: 'absolute',
      bottom: '14px',
      right: '12px',
      zIndex: 999,
      backgroundColor: '#ffffff',
      padding: '10px',
      borderRadius: '5px',
      boxShadow: '0 1px 5px rgba(0,0,0,0.4)',
      minWidth: '130px',
    },
    legendIcon: {
      position: 'absolute',
      bottom: '5px',
      right: '5px',
      width: '42px',
      height: '42px',
      zIndex: 999,
      cursor: 'pointer',
      padding: '5px',
    },
    header: {
      margin: '0 0 8px 0',
      fontSize: '14px',
      color: '#0061AA',
      fontWeight: '700',
    },
    hr: {
      borderTop: '1px solid black',
      margin: '5px 0 8px 0',
    },
    legendItem: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '5px',
    },
    colorBox: {
      width: '12px',
      height: '12px',
      marginRight: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    label: {
      fontSize: '11px',
    },
  };

  return (
    <>
      {showLegend ? (
        <div
          className="legend-container"
          onMouseEnter={() => setShowLegend(true)}
          onMouseLeave={() => setShowLegend(false)}
          style={styles.legendContainer}
        >
          <h4 style={styles.header}>Legends</h4>

          <div style={{marginBottom: 10}}>
            <span style={{ fontSize: '14px' }}>Slope</span>
            <hr style={styles.hr} />
            {riskRanking.map((x) => (
              <div key={x.id} style={styles.legendItem}>
                <div style={styles.colorBox}>
                  <img src={SlopeMarker[x.id]} alt={x.name} width="18" height="18" />
                </div>
                <span style={styles.label}>{x.name}</span>
              </div>
            ))}
          </div>

          <span style={{ fontSize: '14px' }}>Bridge</span>
          <hr style={styles.hr} />
          {bridgeType.map((x) => (
            <div key={x.id} style={styles.legendItem}>
              <div style={styles.colorBox}>
                <img src={BridgeMarker[x.id]} alt={x.name} width="20" height="20" />
              </div>
              <span style={styles.label}>{x.name}</span>
            </div>
          ))}
        </div>
      ) : (
        <img
          src={legendIcon}
          alt="legendIcon"
          onMouseEnter={() => setShowLegend(true)}
          style={styles.legendIcon}
        />
      )}
    </>
  );
};

export default MapLegend;
