import React, { useState } from 'react'
import FilterView from './filterView';
import LineVisibility from './LineVisibility';

const CustomFilterComponent = ({
  detectionType, hasFilter, set_selected_project, setShowSidebar, filteredDetectionTypes,
  setFilteredDetectionTypes, selectedDetection, onHoverComponent, setOnHoverComponent,
  setFilterMarkerType, toggleLineLayer, setToggleLineLayer, toggleMarkerIcon, setToggleMarkerIcon, onClickDetection,
  filteredAssetType, setFilteredAssetType,
}) => {
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const [openVisibilityMenu, setOpenVisibilityMenu] = useState(false);
  const onClickMenu = (type) => {
    if (type === 'filter') {
      setOpenFilterMenu(prev => !prev);
      setOpenVisibilityMenu(false);
      return;
    };
    setOpenFilterMenu(false);
    setOpenVisibilityMenu(prev => !prev);
  }
  return (
    <>
      {detectionType?.length && hasFilter && (
        <FilterView
          openFilterMenu={openFilterMenu}
          setOpenFilterMenu={setOpenFilterMenu}
          detectionType={detectionType}
          set_selected_project={set_selected_project}
          setShowSidebar={setShowSidebar}
          filteredDetectionTypes={filteredDetectionTypes}
          setFilteredDetectionTypes={setFilteredDetectionTypes}
          selectedDetection={selectedDetection}
          onHover={onHoverComponent}
          setOnHover={setOnHoverComponent}
          onClickMenu={onClickMenu}
          setFilterMarkerType={setFilterMarkerType}
          setToggleLineLayer={setToggleLineLayer}
          onClickDetection={onClickDetection}
          filteredAssetType={filteredAssetType}
          setFilteredAssetType={setFilteredAssetType}
        />
      )}
      <LineVisibility
        openVisibilityMenu={openVisibilityMenu}
        setOpenVisibilityMenu={setOpenVisibilityMenu}
        onHover={onHoverComponent}
        setOnHover={setOnHoverComponent}
        onClickMenu={onClickMenu}
        toggleLineLayer={toggleLineLayer}
        setToggleLineLayer={setToggleLineLayer}
        toggleMarkerIcon={toggleMarkerIcon}
        setToggleMarkerIcon={setToggleMarkerIcon}
        onClickDetection={onClickDetection}
      />
    </>
  )
}

export default CustomFilterComponent