import React from 'react';
import { TextField } from '@material-ui/core';

export default function DateTimePickerField({ label, value, onChange }) {
  return (
    <TextField
      label={label}
      type="datetime-local"
      value={value}
      onChange={onChange}
      variant="standard"
      style={{
        width: '370px',
        borderRadius: '20px',
        backgroundColor: 'white',
        padding: '2px 15px',
        fontSize: '12px',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.2)',
      }}
      InputProps={{
        disableUnderline: true,
        style: {
          backgroundColor: 'transparent',
          border: 'none',
          boxShadow: 'none',
        },
      }}
      InputLabelProps={{
        shrink: true,
        style: {
          marginTop: '5px',
          marginLeft: '15px',
        },
      }}
    />
  );
}
