/* eslint-disable max-len */
import { Dialog, DialogContent, IconButton, CardMedia, Select, MenuItem, Menu, Tooltip } from '@material-ui/core';
import { Close, KeyboardArrowDown, KeyboardArrowRight, Edit } from '@material-ui/icons';
import { fromUTC } from '@Helpers';
import { useState, useRef, useEffect } from 'react';
import open_info_menu from "@Assets/Icons/open_info_menu.svg";
import close_info_menu from "@Assets/Icons/close_info_menu.svg";
import { fabric } from 'fabric'; // this also installed on your project
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import detectZoom from 'detect-zoom';
import BoxToggleIcon from '@Assets/Icons/BoxToggleIcon.png';
import BoxUntoggleIcon from '@Assets/Icons/BoxUntoggleIcon.png';
import ResponseTimeProgress from '@Components/ResponseTimeProgress';
import CenteredLoading from '@Components/CenteredLoading';
import moment from 'moment';
import FullScreenImagePreview from '@Components/FullScreenImagePreview';
import TakeActionDialog from '@Components/TakeActionDialog';
import Button from '@Components/Button';

const rectDefaultProps = {
  fill: 'transparent',
  strokeWidth: 1,
  stroke: '#51DBA5',
  hasControls: false,
  hasRotatingPoint: false,
  hasBorders: false,
  strokeUniform: true,
  autoScaleStrokeWidth: false,
};

export default function DialogTable({ selectedAnnotation, allDetectionType, ...props }) {
  const ref = useRef(null);
  const [hideDialogInfo, setHideDialogInfo] = useState(false);
  const [marginAdjustment, setMarginAdjustment] = useState({});
  const [lock_canvas_w, setlock_canvas_w] = useState(0.8 * window.screen.width); // default 10 / 14 * window.screen.width
  const [lock_canvas_h, setlock_canvas_h] = useState(0.8 * window.screen.height); // default 5 / 8 * window.screen.height
  const [onZoomRatio, setOnZoomRatio] = useState(0);
  const [imageSettings, setimageSettings] = useState({});
  const [toggleAnnotationBox, setToggleAnnotationBox] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(null);
  const [openActionDialog, setOpenActionDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  window.onresize = function onresize() {
    const device = detectZoom.device();
    setOnZoomRatio(device);
  };
  let imageSettingsOnLoad = {};
  const userCanEditAnnotation = false;
  // for zoom and pan
  let isDragging = false;
  let lastPosX;
  let lastPosY;
  function onMouseWheel(opt) {
    const { e } = opt;
    zoomDelta(editor.canvas, e.deltaY, e.offsetX, e.offsetY);
    e.preventDefault();
    e.stopPropagation();
  }

  const zoomDelta = (canvas, delta, x, y, maxZoom = 10, minZoom = 0.1) => {
    let zoom = canvas.getZoom();
    zoom *= 0.999 ** delta;
    zoom = Math.min(zoom, maxZoom);
    zoom = Math.max(zoom, minZoom);
    const point = {
      x,
      y,
    };
    canvas.zoomToPoint(point, zoom);
    const w = 4;
    const strokeWidth = w / canvas.getZoom();
    const canvasObjects = canvas.getObjects();
    for (let i = 0; i < canvasObjects.length; i++) {
      canvasObjects[i].set('strokeWidth', strokeWidth);
    }
  };

  const handleEditClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleSelectChange = (detectionId) => () => {
    setAnchorEl(null);
    props.editAnnotation(selectedAnnotation.id, detectionId);
  };

  const getClientPosition = (e) => {
    const positionSource = e.touches ? e.touches[0] : e;
    const {
      clientX,
      clientY,
    } = positionSource;
    return {
      clientX,
      clientY,
    };
  };

  const onMouseDown = (opt) => {
    const {
      e,
    } = opt;
    isDragging = true;
    const {
      clientX,
      clientY,
    } = getClientPosition(e);
    lastPosX = clientX;
    lastPosY = clientY;
    editor.canvas.selection = false; // if want to select multiple
    // editor.canvas.discardActiveObject();
  };

  const onMouseMove = (opt) => {
    const activeObject = editor.canvas.getActiveObject();
    if (!!activeObject) return;
    // console.log('ANN DEBUG: only drag canvas when click on canvas', activeObject, !!activeObject);
    if (!isDragging) {
      return;
    }
    const {
      e,
    } = opt;
    const T = editor.canvas.viewportTransform;
    const {
      clientX,
      clientY,
    } = getClientPosition(e);
    T[4] += clientX - lastPosX;
    T[5] += clientY - lastPosY;
    editor.canvas.requestRenderAll();
    lastPosX = clientX;
    lastPosY = clientY;
  };

  const onMouseUp = (opt) => {
    // const { x, y } = opt.absolutePointer;
    editor.canvas.setViewportTransform(editor.canvas.viewportTransform);
    isDragging = false;
    editor.canvas.selection = true;
  };
  const { editor, onReady } = useFabricJSEditor();
  useEffect(() => {
    if (!ref.current) return;
    console.log("ANN DEBUG: Ref Set", ref.current.offsetHeight)
    setlock_canvas_w(ref.current?.offsetWidth)
    setlock_canvas_h(ref.current?.offsetHeight)
  }, [ref.current]);

  useEffect(() => {
    if (!editor) return;
    console.log("ANN DEBUG: Has Editor")
    if (!selectedAnnotation?.['InspectionFile.thumbnail']) return;
    console.log("ANN DEBUG: Has Image")
    if (!(lock_canvas_w || lock_canvas_h)) return;
    console.log("ANN DEBUG: Lock State Before Effect", lock_canvas_w, lock_canvas_h, window.screen.width, window.screen.height)
    fabric.Image.fromURL(`${process.env.REACT_APP_FD_BLOB}/${selectedAnnotation?.['InspectionFile.thumbnail']}`, (oImg) => {
      // console.log('ANN DEBUG: original image main ', selectedAnnotation?.thumbnail);
      console.log('ANN DEBUG: original editor.canvas', editor.canvas);
      console.log('ANN DEBUG: window view port', window.screen.height, window.screen.width);
      const isImgLandscape = oImg.width >= oImg.height;

      console.log('ANN DEBUG: REF', ref.current)
      let wcanvas = lock_canvas_w; // editor.canvas.width;
      let hcanvas = lock_canvas_h; // editor.canvas.height;
      console.log('ANN DEBUG: canvas w', wcanvas, 'canvas h', hcanvas);
      // --- lock ratio by canvas
      // const wratio = 985 / oImg.width;
      // const hratio = 738.75 / oImg.height;
      // --- lock ratio by image
      let wratio = wcanvas / oImg.width;
      let hratio = hcanvas / oImg.height;
      console.log('ANN DEBUG: ratio w', wratio, ' h', hratio);
      if (isImgLandscape) {
        console.log('ANN DEBUG: img is landscape w > h');
        hratio = wratio;
        hcanvas = oImg.height * hratio;
      } else {
        console.log('ANN DEBUG: img is portrait h > w');
        // change isLockImgHeight to false if want full width in potrait
        const isLockImgHeight = false;
        if (isLockImgHeight) {
          console.log('ANN DEBUG: img h is locked');
          // --- fix height to image
          hratio = 1;
          wratio = 1;
          hcanvas = oImg.height;
          wcanvas = oImg.width;
        } else {
          // --- extend height if full width
          console.log('ANN DEBUG: img h is extended');
          hratio = wratio;
          hcanvas = oImg.height * hratio;
        }
      }

      // -- lock canvas height
      console.log('ANN DEBUG: mm window.screen', window.screen.width, window.screen.height);
      // const lock_canvas_w = 10 / 14 * window.screen.width;
      // const lock_canvas_h = window.screen.height - 340; // top(100) + bottom(240)
      console.log('ANN DEBUG: mm original img', oImg.width, oImg.height);
      console.log('ANN DEBUG: mm after potrait landscape', wcanvas, hcanvas);
      console.log('ANN DEBUG: mm lock_canvas', lock_canvas_w, lock_canvas_h, marginAdjustment);
      const lock_ratio_w = lock_canvas_w / oImg.width;
      const lock_ratio_h = lock_canvas_h / oImg.height;
      const canvasMarginAdjustment = {};
      if (oImg.height * lock_ratio_w > lock_canvas_h) {
        // readjusting ratio
        console.log('ANN DEBUG: canvas height more than lock value', lock_canvas_h, hratio);
        hcanvas = lock_canvas_h;
        hratio = lock_ratio_h;
        wratio = hratio;
        wcanvas = oImg.width * wratio;
        console.log('ANN DEBUG: width ref margin adjustment', wcanvas, lock_canvas_w);
        console.log('ANN DEBUG: begin adjustment lock_canvas_w - wcanvas', lock_canvas_w, wcanvas, lock_canvas_w - wcanvas);
        const left_adjustment = (lock_canvas_w - wcanvas) / 2;
        canvasMarginAdjustment.left = left_adjustment;
        setMarginAdjustment({ paddingLeft: left_adjustment });
      } else if (oImg.width * lock_ratio_h > lock_canvas_w) {
        // readjusting ratio
        console.log('ANN DEBUG: canvas width more than lock value', lock_canvas_w, wratio);
        wcanvas = lock_canvas_w;
        wratio = lock_ratio_w;
        hratio = wratio;
        hcanvas = oImg.height * hratio;
        console.log('ANN DEBUG: begin adjustment lock_canvas_h - hcanvas', lock_canvas_h, hcanvas, lock_canvas_h - hcanvas);
        const top_adjustment = (lock_canvas_h - hcanvas) / 2;
        canvasMarginAdjustment.top = top_adjustment;
        setMarginAdjustment({ paddingTop: top_adjustment });
      }
      // if (hcanvas > lock_canvas_h) {
      //   // readjusting ratio
      //   console.log('ANN DEBUG: canvas height more than lock value', lock_canvas_h, hratio);
      //   hcanvas = lock_canvas_h;
      //   hratio = lock_canvas_h / oImg.height;
      //   wratio = hratio;
      //   wcanvas = oImg.width * wratio;
      //   console.log('ANN DEBUG: width ref margin adjustment', wcanvas, lock_canvas_w)
      //   setMarginAdjustment({ paddingLeft: (lock_canvas_w - wcanvas) / 2 });
      // } else if (wcanvas > lock_canvas_w) {
      //   // readjusting ratio
      //   console.log('ANN DEBUG: canvas width more than lock value', lock_canvas_w, wratio);
      //   wcanvas = lock_canvas_w;
      //   wratio = lock_canvas_w / oImg.width;
      //   hratio = wratio;
      //   hcanvas = oImg.height * hratio;
      //   setMarginAdjustment({ paddingTop: (lock_canvas_h - hcanvas) / 2 });
      // }
      // -- end lock canvas height

      console.log('ANN DEBUG: h', oImg.height, 'canvas', hcanvas, 'r', hratio);
      console.log('ANN DEBUG: w', oImg.width, 'canvas', wcanvas, 'r', wratio);
      editor.canvas.setHeight(lock_canvas_h);
      editor.canvas.setWidth(lock_canvas_w);
      const imgSet = {
        image_size_during_annotation_width: wcanvas,
        image_size_during_annotation_height: hcanvas,
        image_intrinsic_size_width: oImg.width,
        image_intrinsic_size_height: oImg.height,
      };
      console.log('ANN DEBUG: imgset:', imgSet);
      // imageSettings = { ...imgSet, canvasMarginAdjustment };
      imageSettingsOnLoad = { ...imgSet };
      setimageSettings({ ...imgSet, canvasMarginAdjustment });
      // end lock ratio
      // editor.canvas.setHeight(oImg.height * hratio);
      console.log('ANN DEBUG: adjusted editor.canvas', editor.canvas);
      editor.canvas.setBackgroundImage(oImg, editor.canvas.renderAll.bind(editor.canvas), {
        ...canvasMarginAdjustment,
        scaleX: wratio,
        scaleY: hratio,
      });
      editor.canvas.remove(...editor.canvas.getObjects());
      const mainImageAnnotations = [selectedAnnotation];
      console.log("ANN DEBUG: mainImageAnnotations", props.selectedDetection.points)
      // drawGridWithNumbers(editor.canvas)
      if (!!toggleAnnotationBox) return;
      for (let annIdx = 0; annIdx < mainImageAnnotations.length; annIdx++) {
        const { id, points, is_close } = mainImageAnnotations[annIdx];
        const {
          left, top, width, height,
          image_size_during_annotation_width,
          image_size_during_annotation_height,
          image_intrinsic_size_width,
          // leftMarginOffset,
          // topMarginOffset,
        } = JSON.parse(points);
        // const leftRatio = wcanvas;
        // const topRatio = hcanvas;
        // console.log('ANN DEBUG: point ratio', leftRatio, topRatio);
        console.log('ANN DEBUG: canvas wt', wcanvas, hcanvas);
        console.log('ANN DEBUG: left top from db', left, top);
        const isLock = (userCanEditAnnotation) ? {} : { lockMovementX: true, lockMovementY: true };
        console.log('ANN DEBUG: canvasMarginAdjustment', canvasMarginAdjustment);
        console.log('ANN DEBUG: annotation left', image_size_during_annotation_width, image_size_during_annotation_width ? left / image_size_during_annotation_width * wcanvas : left);
        console.log('ANN DEBUG: annotation top', image_size_during_annotation_height, image_size_during_annotation_height ? top / image_size_during_annotation_height * hcanvas : top);
        console.log('ANN DEBUG: BOX width height', width, height);
        const rectObj = {
          id,
          is_close,
          left:
            (image_size_during_annotation_width ? left / image_size_during_annotation_width * wcanvas : left) + (canvasMarginAdjustment.left ?? 0), // adding offset
          top:
            (image_size_during_annotation_height ? top / image_size_during_annotation_height * hcanvas : top)
            + (canvasMarginAdjustment.top ?? 0), // adding offset
          width: image_size_during_annotation_width ? width / image_size_during_annotation_width * wcanvas : width,
          height: image_size_during_annotation_height ? height / image_size_during_annotation_height * hcanvas : height,
          leftMarginOffset: canvasMarginAdjustment.left ?? 0,
          topMarginOffset: canvasMarginAdjustment.top ?? 0,
          ...rectDefaultProps,
          stroke: '#51DBA5',
          ...isLock,
        };
        console.log("ANN DEBUG: DRAW", rectObj)
        const rect = new fabric.Rect(rectObj);
        editor.canvas.add(rect);
        // set active for first index
        // if (!annIdx) editor.canvas.setActiveObject(rect);
        // rect.on('selected', somethingChangeInCanvas);
      }
      props.setIsLoading(false);
    });
    // zoom on mouse wheel
    editor.canvas.on('mouse:wheel', onMouseWheel);
    editor.canvas.on('mouse:down', onMouseDown);
    editor.canvas.on('mouse:move', onMouseMove);
    editor.canvas.on('mouse:up', onMouseUp);
    // if (userCanEditAnnotation) editor.canvas.on('object:modified', somethingChangeInCanvas);
    // old style, fabric 2.0 has to bind directly with rect object for dom event
    // editor.canvas.on('object:selected', somethingChangeInCanvas);
  }, [selectedAnnotation?.['InspectionFile.thumbnail'], onZoomRatio, lock_canvas_w, lock_canvas_h, toggleAnnotationBox]);

  useEffect(() => {
    if (!editor) return;
    if (!toggleAnnotationBox) return;
    const nonImageObjects = editor.canvas.getObjects().filter(obj => !(obj instanceof fabric.Image));
    nonImageObjects.forEach(obj => editor.canvas.remove(obj)); // Remove all non-image objects
  }, [editor, toggleAnnotationBox]);
  return (
    <>
      <Dialog
        open={props.selectedDetection}
        onClose={() => props.onClickDetection(null)}
        maxWidth="lg"
        fullWidth
      >
        <IconButton
          color="inherit"
          className='position-absolute'
          style={{ top: 40, right: 40, zIndex: 100, backgroundColor: 'white', padding: 5 }}
          onClick={() => props.onClickDetection(null)}
        >
          <Close style={{ color: 'black', fontSize: 30, padding: 0 }} />
        </IconButton>
        <DialogContent
          className="sidebar position-relative flex-standard"
          style={{
            marginTop: 25,
            marginBottom: 25,
            height: '80vh',
            display: 'flex', // Add flex
            flexDirection: 'column', // Arrange items in a column
            justifyContent: 'space-between', // Space out children 
          }}
        >
          <div className="info-window-v2" style={{ width: "22%", maxHeight: "100%", backgroundColor: 'rgba(255, 255, 255, 0.8)', position: 'absolute', top: 10, left: 40, zIndex: 100, boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', }}>
            <div style={{ height: '100%', overflow: 'auto' }}>
              <div
                className="color-gradient-inline p-2 text-white font-weight-bold d-flex align-items-center"
                style={{ justifyContent: "space-between" }}>
                {selectedAnnotation.annotation_type_name.toUpperCase()}
                <Tooltip title="Change Detection Type">
                  <span>
                    <Edit style={{ fontSize: "18px", cursor: "pointer" }} onClick={handleEditClick} />
                  </span>
                </Tooltip>
                <Menu  style={{ height: 628 }} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                  {allDetectionType.map((detectionType) => (
                    <MenuItem onClick={handleSelectChange(detectionType.id)} selected={detectionType.id === selectedAnnotation?.['DetectionType.id']}>
                      {detectionType.name}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
              {!hideDialogInfo && (
                <>
                  <div className="d-flex p-2 flex-column" >
                    <div className="d-flex justify-content-between">
                      <div className="p-1">
                        <div className="info-label fs-6" style={{ color: '#5174A3', fontSize: 10 }}>Date</div>
                        <div className="font-weight-bold" style={{ color: '#0081B0', fontSize: 12 }}>
                          {fromUTC(selectedAnnotation.detected_at).format("DD MMM YYYY")}
                        </div>
                      </div>
                      <div className="p-1">
                        <div className="info-label" style={{ color: '#5174A3', fontSize: 10 }}>Time</div>
                        <div className="font-weight-bold" style={{ color: '#0081B0', fontSize: 12 }}>
                          {fromUTC(selectedAnnotation.detected_at).format("h:mm A")}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between p-1">
                      <div>
                        <div className="info-label" style={{ color: '#5174A3', fontSize: 10 }}>Location</div>
                        <div className="font-weight-bold" style={{ color: '#0081B0', fontSize: 12 }}>
                          {`${selectedAnnotation?.network ? selectedAnnotation?.network?.toUpperCase() : '-'} (${selectedAnnotation?.section ? selectedAnnotation?.section?.toUpperCase() : '-'}-${selectedAnnotation?.location_area_district ?? '-'})`}
                        </div>
                        <a
                          className="small"
                          target="_blank"
                          href={`http://google.com/maps/place/${selectedAnnotation?.["InspectionFile.lat"]},${selectedAnnotation?.["InspectionFile.lng"]}`}
                          rel="noopener noreferrer"
                          children="Go To Location"
                          style={{ color: '#30D6D6' }} />
                      </div>
                      <div className="p-1">
                        <div className="info-label" style={{ color: '#5174A3', fontSize: 10 }}>Condition</div>
                        <div className="info-data" style={{ color: '#0081B0', fontWeight: 500, fontSize: 12 }}>-</div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between p-1">
                      {
                        [
                          { label: 'Width', value: (selectedAnnotation?.x * 0.01).toFixed(3) ?? 0, unit: 'm' },
                          { label: 'Length', value: (selectedAnnotation?.y * 0.01).toFixed(3) ?? 0, unit: 'm' },
                          { label: 'Area', value: selectedAnnotation?.y ? (selectedAnnotation?.x * selectedAnnotation?.y * 0.0001).toFixed(3) : 0, unit: 'm²', hasSquare: true }
                        ].map(e => (
                          <div>
                            <div
                              className="info-label"
                              style={{ color: '#5174A3', fontSize: 10 }}
                            >
                              {e.label}
                            </div>
                            <div
                              className="font-weight-bold"
                              style={{ color: '#0081B0', fontSize: 12 }}
                            >
                              {e.value ? (
                                <>
                                  {e.value + e.unit}
                                  {/* {e.hasSquare && <sup>2</sup>} */}
                                </>
                              ) : '-'}
                            </div>
                          </div>
                        ))
                      }
                    </div>
                    <div className="d-inline-flex p-1 justify-content-between">
                      {
                        [{ label: 'Confidence', value: Math.round((selectedAnnotation?.confidence ?? 0) * 100), unit: '%' }].map(e => (
                          <div>
                            <div
                              className="info-label"
                              style={{ color: '#5174A3', fontSize: 10 }}
                            >
                              {e.label}
                            </div>
                            <div
                              className="font-weight-bold"
                              style={{ color: '#0081B0', fontSize: 12 }}
                            >
                              {e.value ? (
                                <>
                                  {`${e.value} ${e.unit}`}
                                  {/* {e.hasSquare && <sup>2</sup>} */}
                                </>
                              ) : '-'}
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="color-gradient-inline p-2 font-weight-bold text-white  d-flex justify-content-between">
                    RESPONSE TIME
                    {props.isCollapse.response_time ? (
                      <KeyboardArrowRight className='pointer' onClick={() => props.setIsCollapse(prev => ({ ...prev, response_time: !prev?.response_time }))} />
                    ) : (
                      <KeyboardArrowDown className='pointer' onClick={() => props.setIsCollapse(prev => ({ ...prev, response_time: !prev?.response_time }))} />
                    )}
                  </div>
                  <div className='p-2' style={{ display: props.isCollapse.response_time ? 'none' : 'block' }}>
                    <ResponseTimeProgress DetectionTypeId={selectedAnnotation.DetectionTypeId} createdAt={selectedAnnotation.createdAt} takeActionList={props.takeActionList} />
                  </div>
                  <div className="color-gradient-inline p-2 font-weight-bold text-white d-flex justify-content-between">
                    ACTION
                    {props.isCollapse.action ? (
                      <KeyboardArrowRight className='pointer' onClick={() => props.setIsCollapse(prev => ({ ...prev, action: !prev?.action }))} />
                    ) : (
                      <KeyboardArrowDown className='pointer' onClick={() => props.setIsCollapse(prev => ({ ...prev, action: !prev?.action }))} />
                    )}
                  </div>
                  <div style={{ display: props.isCollapse.action ? 'none' : 'block' }}>
                    {props.isLoadingTakeAction ? (
                      <CenteredLoading size={40} style={{ marginTop: 10, marginBottom: 10 }} />
                    ) : (
                      <>
                        {!props.takeActionList.length ? (
                          <div className='p-2'>
                            <div className="" style={{ color: 'gray', fontSize: 12 }}>
                              NO ACTION
                            </div>
                            <div className="mb-2 font-weight-bold" style={{ color: '#0081B0', fontSize: 10 }}>
                              No action taken yet
                            </div>
                            <div className='d-flex justify-content-between mb-2'>
                              <div className="info-data" style={{ color: '#0081B0', fontWeight: 500, fontSize: 10 }}>
                                {fromUTC(selectedAnnotation.detected_at).format("DD MMM YYYY")}
                              </div>
                              <div className="info-data" style={{ color: '#0081B0', fontWeight: 500, fontSize: 10 }}>
                                {fromUTC(selectedAnnotation.detected_at).format("h:mm A")}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className='p-2' style={{ height: 150, overflow: 'auto' }}>
                            {props.takeActionList.map(action => (
                              <>
                                {action.images && action.images.length && (
                                  <div>
                                    <img className='pointer' src={`${process.env.REACT_APP_FD}/${action.images.split(',')[0]}`} width={150} height={100} onClick={() => setOpenImagePreview(`${process.env.REACT_APP_FD}/${action.images.split(',')[0]}`)} />
                                  </div>
                                )}
                                <div className="" style={{ color: 'gray', fontSize: 12 }}>
                                  {[
                                    { id: 1, label: 'In-Progress' },
                                    { id: 2, label: 'Completed (Temporary)' },
                                    { id: 3, label: 'Completed (Permanent)' },
                                  ].find(e => e.id === action.ActionStatusId)?.label}
                                </div>
                                <div
                                  className="mb-2 font-weight-bold"
                                  style={{ color: '#0081B0', fontSize: 10 }}
                                >
                                  {action.remark}
                                </div>
                                <div className="d-flex justify-content-between mb-2">
                                  <div
                                    className="info-data"
                                    style={{
                                      color: '#0081B0',
                                      fontWeight: 500,
                                      fontSize: 10,
                                    }}
                                  >
                                    {moment(action.takenAt).format('DD MMM YYYY')}
                                  </div>
                                  <div
                                    className="info-data"
                                    style={{
                                      color: '#0081B0',
                                      fontWeight: 500,
                                      fontSize: 10,
                                    }}
                                  >
                                    {moment(action.takenAt).format('h:mm A')}
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        )}
                        <div className="d-flex justify-content-center p-2">
                          <Button
                            onClick={() => setOpenActionDialog(pv => !pv)}
                            disabled={props.isLoadingTakeAction}
                            variant="contained"
                            style={{
                              background: 'linear-gradient(90.17deg, #32A9D1 1.24%, #4CD2AE 99.85%)',
                              color: 'white',
                              marginRight: 10,
                              borderRadius: 20,
                              fontSize: 11,
                            }}
                          >
                            Take Action
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <img
              className="pointer"
              src={hideDialogInfo ? open_info_menu : close_info_menu}
              style={{
                position: 'absolute',
                bottom: hideDialogInfo ? '-50%' : '-4%',
                right: '42%',
                zIndex: 2,
              }}
              onClick={() => setHideDialogInfo((prev) => !prev)}
            />
          </div>
          <div className="w-100 h-100" ref={ref}>
            <FabricJSCanvas className="sample-canvas" onReady={onReady} />
          </div>
          <IconButton
            color="inherit"
            style={{
              backgroundColor: toggleAnnotationBox ? '#082631' : '#30A6D3',
              padding: 5,
              borderRadius: 0,
              alignSelf: 'flex-end', // Align button to the right
              marginBottom: 2,
            }}
            onClick={() => { setToggleAnnotationBox(pv => !pv) }}
          >
            <img src={toggleAnnotationBox ? BoxUntoggleIcon : BoxToggleIcon} style={{ width: 20, height: 20 }} />
          </IconButton>
          <FullScreenImagePreview openImagePreview={openImagePreview} setOpenImagePreview={setOpenImagePreview} />
        </DialogContent>
      </Dialog>
      <TakeActionDialog
        openActionDialog={openActionDialog}
        setOpenActionDialog={setOpenActionDialog}
        createNewAction={props.createNewAction}
      />
    </>
  );
}
