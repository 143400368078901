import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, MenuItem, FormControl, Select, } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
const _ = require('lodash');

const useStyles = makeStyles(() => ({
  formControlContainer: {
    height: '0',
  },
  formControl: {
    width: '100%',
    visibility: 'hidden',
    padding: 0,
  },
}));

const StyledMenuItem = withStyles(() => ({
  root: {
    justifyContent: 'center',
    backgroundColor: 'var(--dark-blue-color)',
    '& h1, h2, h3, h4, h5, h6': {
      textAlign: 'center',
      color: 'white',
    },
    '&:focus, &:hover': {
      backgroundColor: 'white',
      '& h1, h2, h3, h4, h5, h6': {
        color: 'var(--dark-blue-color)',
      },
    },
  },
}))(MenuItem);

export default function Dropdown({
  selectedItem = 0, setSelectedItem, itemList, width, Hx = 'h3', size, tab, multiSelect = false,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    let selectedValues = event.target.value;

    if (multiSelect) {
      if (selectedValues.includes(null)) {
        setSelectedItem([null]);
      } else {
        selectedValues = selectedValues.filter((val) => val !== null);

        const allOtherValues = itemList.filter((x) => x.value !== null).map((x) => x.value);
        if (_.isEqual(selectedValues.sort(), allOtherValues.sort())) {
          setSelectedItem([null]);
        } else {
          setSelectedItem(selectedValues);
        }
      }
    } else {
      setSelectedItem(selectedValues);
    }
  };

  const findLabel = (selection) => {
    if (Array.isArray(selection)) {
      if (selection.length === 0) {
        return itemList[0]?.label || 'Select Detection';
      }
      if (selection.includes(null)) {
        return 'All';
      }
      const labels = selection.map(val => {
        const found = itemList.find(item =>
          Array.isArray(item.value) ? _.isEqual(item.value, val) : item.value === val
        );
        return found ? found.label : '';
      }).filter(Boolean);
      return labels.join(', ');
    } else {
      const predefinedLabels = {
        pavement: { 1: 'Patrol', 2: 'Drone' },
        trafficsafety: { 1: 'Patrol' },
      };
      if (predefinedLabels[tab]?.[selection]) {
        return predefinedLabels[tab][selection];
      }
      const found = itemList.find(item =>
        Array.isArray(item.value)
          ? _.isEqual(item.value, selection)
          : item.value === selection
      );
      return found ? found.label : '';
    }
  };
  return (
    <div>
      <Button
        onClick={handleClick}
        endIcon={<ExpandMore style={{ color: 'var(--dark-blue-color)' }} />}
        variant="contained"
        style={{
          width: width || '100%',
          height: (size === 'small') ? '40px' : '55px',
          borderRadius: '20px',
          backgroundColor: 'white',
          color: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          lineHeight: 'normal',
        }}
      >
        <div>
          <Hx style={{ color: 'var(--dark-blue-color)', fontSize: '12px', fontWeight: 400 }}>
            {findLabel(selectedItem)}
          </Hx>
        </div>
      </Button>
      <div className={classes.formControlContainer}>
        <FormControl className={classes.formControl}>
          <Select
            value={selectedItem}
            multiple={multiSelect}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onChange={handleChange}
            MenuProps={{
              PaperProps: {
                style: {
                  borderRadius: '10px',
                },
              },
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              getContentAnchorEl: null,
              MenuListProps: { disablePadding: true },
            }}
          >
            {itemList?.map((x) => (
              <StyledMenuItem
                // selected={selectedItem}
                value={x.value}
                dense
              >
                <Hx>{x.label}</Hx>
              </StyledMenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
