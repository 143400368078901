import React from 'react';
import _ from 'lodash';
import { Table, Button } from 'reactstrap';
import { Paper, makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { adjustedArea, fromUTC, laneAndLocationAreaName } from '@Helpers';
import DialogTable from './DialogTable';
import NoData from '@Assets/Images/Data-not-found3.png';

const styles = {
  button: {
    backgroundColor: '#36404A',
    border: 'thin solid grey',
    borderRadius: '10px',
  },
  headerContainer: {
    paddingTop: '1em',
    paddingBottom: '1em',
  },
  headerButton: {
    backgroundColor: '#161944',
    borderColor: '#00A8CC',
    borderRadius: '30px',
    marginLeft: '10px',
  },
  tableHeader: {
    color: 'white',
    fontSize: '1em',
    backgroundColor: 'linear-gradient(to right, var(--main-color), var(--primary-color))',
  },
  iconInTable: { margin: '0 7px 0 7px', fontSize: '1.5em', cursor: 'pointer' },
};
const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      color: 'gray',
      borderColor: 'gray',
    },
    '& .Mui-selected': {
      color: 'white !important',
      backgroundColor: '#4CD2AE',
    },
  },
}));
export default ({
  h, tableList, isFiltered, reports,
}) => {
  const classes = useStyles();
  const sortedTableList = _.orderBy(tableList, [(eachAnnotation) => new Date(eachAnnotation.detected_at)], ['desc']);
  const tableListPerpage = sortedTableList?.splice((h.page - 1) * h.perpage, h.perpage);
  return (
    <div style={{ fontFamily: 'CeraProRegular' }}>
      <div className="d-flex justify-content-end">
        {!!isFiltered && (
          <div className="flex-item">
            <Button variant="outlined" style={styles.button} className="float-right my-3 mx-2" onClick={h.onNoBarClick}>Reset Selection</Button>
          </div>
        )}
        <div className="flex-item">
          <Pagination
            count={Math.ceil(tableList?.length / h.perpage)}
            variant="outlined"
            classes={{ ul: classes.ul }}
            page={parseInt(h.page)}
            onChange={(_, v) => h.setPage(v)}
            className="float-right my-3"
          />
        </div>
      </div>
      {!!h.selectedDetection && (<DialogTable {...h} selectedAnnotation={h.selectedAnnotation} />)}
      <Table
        className="text-center analytic-table mb-0"
        style={{
          backgroundColor: 'white',
          boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.2)',
          borderRadius: '20px',
          overflow: 'hidden',
        }}
      >
        <thead>
          <tr style={{ backgroundColor: '#4FD8AA', color: 'white' }}>
            <th>ID</th>
            <th>Detection Photo</th>
            <th>Detection</th>
            <>
              <th>Width</th>
              <th>Length</th>
              <th>Area</th>
            </>
            <th>Network</th>
            <th>Location</th>
            <th>Region</th>
            <th>Section</th>
            <th>Date</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {tableListPerpage.length ? (
            tableListPerpage.map((eachAnnotation) => (
              <tr
                key={eachAnnotation.id}
                onClick={() => h.onClickDetection(eachAnnotation)}
                className="pointer"
                style={{ backgroundColor: 'white' }}
              >
                <td>{eachAnnotation.id}</td>
                <td>
                  <img
                    src={`${process.env.REACT_APP_FD}/${eachAnnotation?.["InspectionFile.thumbnail"]}`}
                    style={{ width: 100, height: 50 }}
                    alt="Annotation"
                  />
                </td>
                <td className="text-capitalize">
                  {eachAnnotation.annotation_type_name}
                </td>
                <>
                  <td>{eachAnnotation.x ? (eachAnnotation.x * 0.01).toFixed(3) : 0} m</td>
                  <td>{eachAnnotation.y ? (eachAnnotation.y * 0.01).toFixed(3) : 0} m</td>
                  <td>
                    {adjustedArea(eachAnnotation.x ?? 0, eachAnnotation.y ?? 0).toFixed(3)} m<sup>2</sup>
                  </td>
                </>
                <td>{eachAnnotation?.network ? eachAnnotation?.network?.toUpperCase() : '-'}</td>
                <td>{eachAnnotation.location_area_district ?? '-'}</td>
                <td>{eachAnnotation.region ? (eachAnnotation.region === 'Central' || eachAnnotation.region === 'N/A' ? eachAnnotation.region : `${eachAnnotation.region}ern`) : null}</td>
                <td>{eachAnnotation.section ?? '-'}</td>
                <td>{fromUTC(eachAnnotation.detected_at).format('Do MMMM YYYY')}</td>
                <td>{fromUTC(eachAnnotation.detected_at).format('h:mm A')}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12">
                <div className="d-flex justify-content-center align-items-center">
                  <img src={NoData} alt="No Data" style={{ height: '220px' }} />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

