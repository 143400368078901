/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import {
  Button, Grid, IconButton, makeStyles, Tooltip,
} from '@material-ui/core';
import Table from '@Components/MaterialTable/v7';
import Navbar from '@Components/Navbar';
import DeleteDialog from '@Components/DeleteDialog';
import moment from 'moment';
import { isValidHttpUrl, initialsFromUser } from '@Helpers';
import DataNotFoundImg from '@Assets/Images/Data-not-found3.svg';
import MainContentContainer from '@Components/MainContentContainer';
import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import { Delete } from '@material-ui/icons';
import PaginationV2 from '@Components/PaginationV2';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Hook from './hook';
import UploadARModelDialog from '@Pages/ProjectSite/components/UploadARModelDialog';

const useStyles = makeStyles({
  centeredHeader: {
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
});

const searchCategory = [
  { key: 'cycle', label: 'Cycle' },
  { key: 'year', label: 'Year' },
];

const avstyle = {
  width: '2.5em',
  height: '2.5em',
  borderRadius: '50%',
  fontSize: '12px',
  backgroundColor: '#506288',
  color: 'white',
  objectFit: 'cover',
  paddingBottom: '1px',
  marginLeft: 'auto',
  marginRight: 'auto',
  boxShadow: '0.5px 0.5px 4px 1px rgba(0,0,0,0.1)',
};

export default function ARModel(props) {
  const h = Hook(props);
  const classes = useStyles();
  const [data, setData] = useState([]);

  const columns = [
    {
      name: 'No',
      selector: 'id',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'left' } }),
        setCellProps: () => ({ style: { textAlign: 'left' } }),
        sort: false,
      },
    },
    {
      name: 'Model Name',
      selector: 'name',
      options: {
        setCellHeaderProps: () => ({ align: 'left' }),
        setCellProps: () => ({ align: 'left' }),
        sort: false,
      },
    },
    {
      name: 'Uploaded Date',
      selector: 'uploadedAt',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Uploader',
      selector: 'userimage',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
    {
      name: 'Actions',
      selector: 'actions',
      options: {
        setCellHeaderProps: () => ({ align: 'center' }),
        setCellProps: () => ({ align: 'center' }),
        sort: false,
      },
    },
  ];

  const ViewBtn = ({ id, path }) => (
    <a href={`ofoar://open?model=https://ahadd-cdn.azureedge.net/ahadd-container/${path}`}>
      <Tooltip title="View Inspection" placement="top">
        <IconButton className="color-gradient-inline" style={{ width: 18, height: 18 }}>
          <VisibilityIcon style={{ color: 'white' }} height="18px" width="18px" />
        </IconButton>
      </Tooltip>
    </a>
  );

  const DeleteBtn = (model) => {
    const [open, setOpen] = useState(false);
    return (
      <>
        <Tooltip title="Delete Augmented Model" placement="top">
          <Delete
            style={{
              color: 'red', width: 34, height: 34, cursor: 'pointer',
            }}
            onClick={() => setOpen(true)}
          />
        </Tooltip>
        <DeleteDialog
          open={open}
          setOpen={setOpen}
          selected={model}
          deleteFunction={() => h.deleteARModel(model.id)}
        />
      </>
    );
  };

  const userImage = ({ User }) => {
    return (
      User?.image ? (
        <Tooltip title={User.name ?? User.email} className="flex-standard" placement="top">
          <img
            src={isValidHttpUrl(User.image)
              ? User.image
              : `${process.env.REACT_APP_FD}/${User.image}`}
            style={avstyle}
          />
        </Tooltip>
      ) : (
        <Tooltip title={User?.name ?? User?.email} placement="top">
          <div className="flex-standard" style={avstyle}>{initialsFromUser({ name: User?.name ?? User?.email })}</div>
        </Tooltip>
      )
    );
  };
  const actionBtn = (x) => {
    return (
      <div className="d-flex justify-content-around align-items-center" style={{ gap: 2, transform: 'scale(0.8)' }}>
        <ViewBtn {...x} />
        <DeleteBtn {...x} />
      </div>
    );
  };

  useEffect(() => {
    setData(h.modelList.map((model, idx) => ({
      ...model,
      id: (h.page - 1) * h.perPage + idx + 1,
      uploadedAt: moment(model.createdAt).format('DD MMMM YYYY'),
      createdAt: moment(model.createdAt).format('DD MMMM YYYY / HH:mm'),
      userimage: userImage(model),
      actions: actionBtn(model),
    })));
  }, [h.modelList]);
  useEffect(() => {
    if (!h.project_id) return;
    if (!props.selectedPhaseWithViewPageAccess.length) return <Redirect to="/project" />;
  }, [h.project_id]);
  return (
    <MainContentContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Navbar
          to="/project/"
          text="AR Model"
        />
        <div className="d-flex align-items-center">
          <UploadARModelDialog {...h} />
        </div>
      </div>
      {h.isLoading ? <CenteredLoadingContainer height="50vh" size={75} hasText text="models" /> : (
        <Grid
          container
          item
          xs={12}
          style={{
            paddingLeft: 0, paddingRight: 20, paddingTop: 0, paddingBottom: 20,
          }}
          className="flex-standard"
        >
          <Grid item xs={12}>
            <PaginationV2 keysList={searchCategory} totalData={h.totalData} onRefresh={h.refresh} isAssetList={true} {...h} />
          </Grid>
          {!!h.modelList?.length ? (
            <Grid item xs={12}>
              <Table {...h} columns={columns} tableData={data} />
            </Grid>
          ) : (
            <div className="d-flex justify-content-center">
              <img src={DataNotFoundImg} style={{ width: '30vw' }} />
            </div>
          )}
        </Grid>
      )}
    </MainContentContainer>
  );
}
