/* eslint-disable max-len */
/* eslint-disable max-nested-callbacks */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-depth */
/* eslint-disable complexity */
import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import { Map } from 'react-leaflet';
import * as L from 'leaflet';
import _ from 'lodash';
import LeafletMarker from '@Components/MapMarker/LeafletMarker';
import OrthophotoCard from '@Components/Map/Orthophoto';
import './map.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-groupedlayercontrol/dist/leaflet.groupedlayercontrol.min.js';
import 'leaflet-draw';
import './leaflet.draw.css';
import uuid from 'react-uuid';
import moment from 'moment';
import NoTagImage from '@Assets/Images/no-tag-image.png';
import MapLegend from '@Components/MapLegend';
import useBaseLayerHook from './BaseLayerHook';
import {
  genLyr, centeringMap, setBaseLayerActive, setGroupLayerOverlayActive, clearingMapLayers, clearingMapMarkersByLatLng,
} from './helper';
import { drawOptions, customColor } from './drawer';
import KM_Marker from './MapLayer/KM_Marker1.json';
import PLUS_Alignment from './MapLayer/PLUS_Alignment.json';
import LPT_Alignment from './MapLayer/LPT_Alignment.json';
import FilterView from './filterView';
import LineVisibility from './LineVisibility';
import CustomFilterComponent from './CustomFilterComponent';

export default function Maps({
  filtered_projects = [],
  selected_project = 0, set_selected_project = () => null,
  showSidebar, setShowSidebar = () => null,
  hasFilter,
  project,
  asset_files,
  mapStyle = {
    position: 'fixed', top: 0, bottom: 0, right: 0, left: 0,
  },
  iconType = 'GreenIcon',
  isDrawAnnotation = false,
  isInspection = false,
  annotationProps,
  detectionType,
  filteredDetectionTypes,
  setFilteredDetectionTypes,
  selectedDetection,
  detections,
  isProjectSite = false,
  toggleLineLayer,
  setToggleLineLayer,
  toggleMarkerIcon = true,
  setToggleMarkerIcon,
  openImageHistory = false,
  onClickDetection = () => null,
  setDetections = () => null,
  filteredAssetType,
  setFilteredAssetType,
  bridgeType,
  riskRanking,
}) {
  const mapRef = useRef();
  const markerStyle = LeafletMarker();
  const basemapHook = useBaseLayerHook(); // load all base map
  const [activeBase, set_activeBase] = useState(null);
  const [center, set_center] = useState([1.496798, 103.704124]);
  const [zoom, set_zoom] = useState(10);
  const [activeControl, setActiveControl] = useState(null);
  const [activeEditControl, setActiveEditControl] = useState(null);
  const [zoomControl, setZoomControl] = useState(null);
  const [existingLayerIndex, set_existingLayerIndex] = useState([]);
  const [timeSeriesIndex, set_timeSeriesIndex] = useState(null);
  const [dialogTimeSeries, set_dialogTimeSeries] = useState(false);
  const [timeSeriesLength, set_timeSeriesLength] = useState({});
  const [dateTimeline, set_dateTimeline] = useState({});
  const [activeTileSeries, set_activeTileSeries] = useState(null);
  const [initialReady, set_initialReady] = useState(false);
  const [selectedMarker, set_selectedMarker] = useState(null);
  const [maxZoomStatus, set_maxZoomStatus] = useState(false);
  const [geoJSONLayerComponent, setGeoJSONLayerComponent] = useState(null);
  const [onHoverComponent, setOnHoverComponent] = useState(false);
  const [filterMarkerType, setFilterMarkerType] = useState(null);
  const [showLegend, setShowLegend] = useState(false);
  const filterMarkers = async (filterType) => {
    await mapRef.current.leafletElement.eachLayer(layer => {
      if (layer.options.layer_type == 'marker' && layer.options.markergroup != filterType) {
        // console.log("MAPDEBUG: MMM3", filterType, layer.options.markergroup)
        mapRef?.current?.leafletElement?.removeLayer(layer); // Hide marker
      }
    });
  };
  useEffect(() => {
    if (toggleMarkerIcon) return;
    filterMarkers('all');
  }, [toggleMarkerIcon]);
  useEffect(() => {
    // console.log('MAPDEBUG: initial', activeControl);
    if (activeControl) mapRef.current.leafletElement.removeControl(activeControl);
    const zoomctrl = L.control.zoom({ position: 'topright' });
    mapRef.current.leafletElement.addControl(zoomctrl);
    setZoomControl(zoomctrl);
    const control = new L.Control.GroupedLayers(basemapHook);
    mapRef.current.leafletElement.addControl(control);
    setBaseLayerActive(basemapHook['Azure Imagery'], mapRef.current.leafletElement); // set active basemap , control will keep track and smart enough
    setActiveControl(control);
    set_initialReady(true);
  }, []);

  // useEffect(() => {
  //   console.log('MAPDEBUG: activeControl change', activeControl);
  // }, [activeControl]);
  const bounds = useMemo(() => {
    // console.log('MAPDEBUG: Generate markers pass', !filtered_projects.length, !mapRef.current, !initialReady);
    if (!filtered_projects.length || !mapRef.current || !initialReady || !toggleMarkerIcon) return;
    const markers = filtered_projects.filter((d) => d.lat).map((m, i) => ([m.lat, m.lng]));
    const markersBounds = L.latLngBounds();
    markers.forEach((marker) => markersBounds.extend(marker));
    // console.log('MAPDEBUG:[MEMO] is Draw:', isDrawAnnotation);
    if (!isDrawAnnotation) {
      // console.log('MAPDEBUG:[MEMO] Not In Drawing Mode');
      mapRef.current.leafletElement.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          mapRef?.current?.leafletElement?.removeLayer(layer);
        }
      });
      filtered_projects.filter((d) => d.lat).forEach((m, idx) => {
        if (m.id === project?.id) return;
        // console.log('MAPDEBUG:[MEMO] marker ',m.lat, m.lng, m)
        L.marker([m.lat, m.lng], {
          icon: iconType === 'WithImage' ? markerStyle.ImageIcon({
            url: !!m.tagImage ? `${process.env.REACT_APP_FD}/${m.tagImage}` : NoTagImage,
          }) : markerStyle.RankingMarker({ ranking: m?.RankingId ?? 1, AssetTypeId: m?.AssetTypeId, bridge_type: m?.bridge_type }),
          layer_type: 'marker',
          markergroup: 'assetMarker',
          riseOnHover: true,
        }).addTo(mapRef.current.leafletElement)
          .on('click', (e) => {
            // console.log('MAPDEBUG: latlng clicked marker', e.latlng);
            set_selected_project(idx);
            (L.geoJSON(JSON.parse(m.polygon))).addTo(mapRef.current.leafletElement);
            setShowSidebar(!(showSidebar && selected_project === idx));
            //   mapRef.current.leafletElement.setView(e.latlng, 18); // without animation
            mapRef.current.leafletElement.flyTo(e.latlng, 18, { animate: true, duration: 2.5 }); // with animation
          }).bindTooltip(m.name);
      });
      if (filterMarkerType) filterMarkers(filterMarkerType);
    }
    return markersBounds;
  }, [initialReady, filtered_projects, project, filterMarkerType, toggleMarkerIcon]);

  useEffect(async () => {
    if (_.isEmpty(project) || !project?.lng || !initialReady) return;
    const targetCenter = { lat: project.lat, lng: project.lng, zoom: 13 };
    centeringMap(mapRef.current.leafletElement, targetCenter);
    let markerIcon = [];
    if (!isDrawAnnotation) {
      // console.log('MAPDEBUG:[Effect] is Draw:', isDrawAnnotation, selectedMarker);
      if (!!selectedMarker && !!maxZoomStatus) mapRef.current.leafletElement.addLayer(selectedMarker);
      // if (!!selectedMarker) mapRef.current.leafletElement.removeLayer(selectedMarker);
      mapRef.current.leafletElement.createPane('locationMarker');
      mapRef.current.leafletElement.getPane('locationMarker').style.zIndex = 999;
      markerIcon = L.marker([targetCenter.lat, targetCenter.lng], {
        icon: iconType === 'WithImage'
          ? markerStyle.SelectedImageIcon({ url: !!project.tagImage ? `${process.env.REACT_APP_FD}/${project.tagImage}` : NoTagImage }) : markerStyle.SelectedMarker({ AssetTypeId: project?.AssetTypeId }),
        layer_type: 'marker',
        pane: 'locationMarker',
      });
      markerIcon.addTo(mapRef.current.leafletElement);
      set_selectedMarker(markerIcon);
    }
  }, [initialReady, project]);

  useEffect(async () => {
    if (!initialReady) return;
    if (!isProjectSite) return;
    if (!detectionType) return;
    if (!detectionType.length) return;
    if (!detections.length) return;
    if (!toggleMarkerIcon) return;
    if (!isDrawAnnotation) {
      if (filterMarkerType) await filterMarkers(filterMarkerType);
      const detectionMarkers = detections.map(ann => ({
        id: ann.id,
        lat: ann.InspectionFile.lat,
        lng: ann.InspectionFile.lng,
        DetectionTypeId: ann.DetectionTypeId,
      }));
      console.log('MAPDEBUG:[Effect] is Annotation Marker:', detectionMarkers);
      detectionMarkers.filter((d) => d.lat).forEach((m, idx) => {
        L.marker([m.lat, m.lng], {
          icon: markerStyle.SelectedDetection({ DetectionTypeId: m.DetectionTypeId ?? null }), // temp while waiting for detection marker, later change to ann.DetectionTypeId
          // icon: markerStyle.SelectedImageIcon({ url: !!m.thumbnail ? `${process.env.REACT_APP_FD}/${m.thumbnail}` : NoTagImage }),
          layer_type: 'marker',
          markergroup: 'detectionMarker',
          riseOnHover: true,
        }).addTo(mapRef.current.leafletElement)
          .on('click', (e) => onClickDetection(m.id))
          .bindTooltip(detectionType.find(f => f.id === m.DetectionTypeId).name);
      });
    };
  }, [initialReady, detectionType, detections, selectedDetection, toggleMarkerIcon, toggleLineLayer]);

  useEffect(async () => {
    if (_.isEmpty(project) || !project?.lng || !initialReady) return;
    if (!!isProjectSite) return;
    if (!detectionType) return;
    if (!detectionType.length) return;
    if (!isDrawAnnotation) {
      const detectionMarkers = (await Promise.all(project.aerial_inspections_details.map(async ({
        InspectionFiles,
      }) => (await Promise.all(InspectionFiles.map(({
        thumbnail, Annotations, lat, lng, metadata, id,
      }) => Annotations.map((ann) => ({
        InspectionFileId: id,
        id: ann.id,
        lat,
        lng,
        metadata,
        thumbnail,
        DetectionTypeId: ann.DetectionTypeId,
        ai_detection: ann.ai_detection,
        createdAt: ann.createdAt,
        detectedAt: ann.detectedAt,
        points: ann.points,
        description: ann.description ?? detectionType.find(dt => dt.id == ann.DetectionTypeId).name,
      }))))).flat()))).flat();
      console.log('MAPDEBUG:[Effect] is Annotation Marker:', detectionMarkers);
      detectionMarkers.filter((d) => d.lat && filteredDetectionTypes.includes(d.DetectionTypeId)).forEach((m, idx) => {
        L.marker([m.lat, m.lng], {
          icon: markerStyle.SelectedDetection({ DetectionTypeId: m.DetectionTypeId ?? null }),
          layer_type: 'marker',
          markergroup: 'detectionMarker',
          riseOnHover: true,
        }).addTo(mapRef.current.leafletElement)
          .bindTooltip(m.description ?? m.thumbnail);
      });
    }
  }, [initialReady, detectionType, project, filteredDetectionTypes]);
  useEffect(() => {
    if (filterMarkerType === 'assetMarker') {
      return setDetections([]);
    };
  }, [filterMarkerType]);
  useEffect(() => {
    if (!!PLUS_Alignment && initialReady && toggleLineLayer) {
      const alignmentLine = L.geoJSON(PLUS_Alignment, {
        onEachFeature: (feature, layer) => {
          if (feature.geometry.type === 'LineString' || feature.geometry.type === 'MultiLineString') {
            L.polyline(layer.getLatLngs(), { color: 'blue', layer_type: 'line', });
          }
        },
      });
      alignmentLine.addTo(mapRef.current.leafletElement);
    }
    if (!!LPT_Alignment && initialReady && toggleLineLayer) {
      const alignmentLine = L.geoJSON(LPT_Alignment, {
        onEachFeature: (feature, layer) => {
          if (feature.geometry.type === 'LineString' || feature.geometry.type === 'MultiLineString') {
            L.polyline(layer.getLatLngs(), { color: 'blue', layer_type: 'line', });
          }
        },
      });
      alignmentLine.addTo(mapRef.current.leafletElement);
    }
    if (!toggleLineLayer) {
      return mapRef.current.leafletElement.eachLayer(layer => {
        if (layer.options.layer_type == 'marker') return;
        if (['LineString', 'MultiLineString'].includes(layer?.feature?.geometry?.type)) {
          mapRef?.current?.leafletElement?.removeLayer(layer); // Hide marker
        }
      });
    }
  }, [PLUS_Alignment, LPT_Alignment, initialReady, toggleLineLayer]);
  useEffect(() => {
    if (geoJSONLayerComponent && mapRef.current) {
      return () => {
        mapRef?.current?.leafletElement?.removeLayer(geoJSONLayerComponent);
        geoJSONLayerComponent.clearLayers();
      };
    }
  }, [geoJSONLayerComponent, mapRef]);
  useEffect(() => {
    if (onHoverComponent) return mapRef.current.leafletElement.scrollWheelZoom.disable();
    mapRef.current.leafletElement.scrollWheelZoom.enable();
  }, [onHoverComponent]);
  return (
    <Map
      ref={mapRef}
      center={center}
      zoomControl={false}
      zoom={zoom}
      maxZoom={18}
      scrollWheelZoom
      tap={false}
      bounds={bounds}
      style={{ ...mapStyle }}
      onbaselayerchange={(e) => { set_activeBase(e.layer.options.name); }}
      onoverlayadd={(e) => {
        set_existingLayerIndex(prev => [...prev, e.layer.options.idx]);
        const addedlayers = e.layer._layers;
        console.log('MAPDEBUG: Overlay add', e.layer);
        // const ops = Object.values(addedlayers)[0].options;
        if (!!e.layer.options.isTimeSeries) {
          // console.log('MAPDEBUG: adding time series', assetFiles_timeSeries, ops.idx);
          set_activeTileSeries(e.layer.options.layer_name);
          set_dialogTimeSeries(true);
        }
        if (isDrawAnnotation) {
          if (!!e.layer.options.isTimeSeries) return;
          const prevActiveIdx = [...annotationProps.mainImageActiveLayers];
          prevActiveIdx.push(e.layer.options.idx);
          annotationProps.setMainImageActiveLayers(_.uniq(prevActiveIdx));
        }
      }}
      onoverlayremove={(e) => {
        set_existingLayerIndex(prev => prev.filter(p => p != e.layer.options.idx));
        if (isDrawAnnotation) {
          let prevActiveIdx = [...annotationProps.mainImageActiveLayers];
          prevActiveIdx = prevActiveIdx.filter((val) => val !== e.layer.options.idx);
          annotationProps.setMainImageActiveLayers(_.uniq(prevActiveIdx));
        }
      }}
      onzoomend={() => {
        const geoJSONLayer = L.geoJSON(KM_Marker, {
          pointToLayer: (feature, latlng) => {
            return L.marker([latlng.lat, latlng.lng], {
              icon: markerStyle.KMIcon({ label: feature.properties.KM }),
              layer_type: 'marker',
              riseOnHover: true,
            });
          },
        });
        setGeoJSONLayerComponent(null);
        if (mapRef.current.leafletElement.getZoom() > 15) {
          setGeoJSONLayerComponent(geoJSONLayer);
          geoJSONLayer.addTo(mapRef.current.leafletElement);
        }
      }}
    >
      {!!activeTileSeries && (
        <div className="w-100" style={{ position: 'absolute', bottom: '0%', zIndex: 999 }}>
          <OrthophotoCard
            open={dialogTimeSeries}
            set_value={set_timeSeriesIndex}
            value={timeSeriesIndex}
            max={activeTileSeries ? timeSeriesLength[activeTileSeries] : 100}
            dateRange={dateTimeline[activeTileSeries]}
            isDrawAnnotation={!!isDrawAnnotation}
            isInspection={!!isInspection}
          />
        </div>
      )}
      <CustomFilterComponent
        detectionType={detectionType}
        hasFilter={hasFilter}
        set_selected_project={set_selected_project}
        setShowSidebar={setShowSidebar}
        filteredDetectionTypes={filteredDetectionTypes}
        setFilteredDetectionTypes={setFilteredDetectionTypes}
        selectedDetection={selectedDetection}
        onHoverComponent={onHoverComponent}
        setOnHoverComponent={setOnHoverComponent}
        setFilterMarkerType={setFilterMarkerType}
        toggleLineLayer={toggleLineLayer}
        setToggleLineLayer={setToggleLineLayer}
        toggleMarkerIcon={toggleMarkerIcon}
        setToggleMarkerIcon={setToggleMarkerIcon}
        onClickDetection={onClickDetection}
        filteredAssetType={filteredAssetType}
        setFilteredAssetType={setFilteredAssetType}
      />
      <MapLegend showLegend={showLegend} setShowLegend={setShowLegend} bridgeType={bridgeType} riskRanking={riskRanking} />
    </Map>
  );
}
