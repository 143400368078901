/* eslint-disable guard-for-in */
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import _ from 'lodash';
import { toast } from 'react-toastify';

export default function Hook() {
  const [isLoading, setIsLoading] = useState(false);
  const [allReports, setAllReports] = useState([]);
  const [filterReports, setFilterReports] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [sections, setSections] = useState([]);
  const [regions, setRegions] = useState([]);
  const [rankings, setRankings] = useState([]);
  const [detectionType, setDetectionType] = useState([]);
  const [selectedCycleFilter, setSelectedCycleFilter] = useState([]);
  const [selectedYearFilter, setSelectedYearFilter] = useState([]);
  const [selectedNetworkFilter, setSelectedNetworkFilter] = useState([]);
  const [selectedRegionFilter, setSelectedRegionFilter] = useState([]);
  const [selectedSectionFilter, setSelectedSectionFilter] = useState([]);
  const [selectedRankingFilter, setSelectedRankingFilter] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedReport, setSelectedReport] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [yearList, setYear] = useState([]);
  const [tab, setTab] = useState('slope');
  const [isGenerateReportLoading, setIsGenerateReportLoading] = useState(false);
  const [openGenerateReportDialog, setOpenGenerateReportDialog] = useState(false);
  const [generateReportSource, setGenerateReportSource] = useState();
  const [generateReportNetwork, setGenerateReportNetwork] = useState();
  const [generateReportSection, setGenerateReportSection] = useState();
  const [generateReportDetection, setGenerateReportDetection] = useState([]);
  const [startGenerateReportDate, setStartGenerateReportDate] = useState(`${new Date().getFullYear()}-01-01T00:00`);
  const [endGenerateReportDate, setEndGenerateReportDate] = useState(new Date(Date.now() + 8 * 60 * 60 * 1000).toISOString().slice(0, 16));
  const tabList = [
    { selector: 'slope', label: 'SLOPE', AssetTypeId: 1 },
    { selector: 'bridge', label: 'BRIDGE', AssetTypeId: 2 },
    { selector: 'pavement', label: 'PAVEMENT', AssetTypeId: 3 },
    { selector: 'trafficsafety', label: 'TRAFFIC & SAFETY', AssetTypeId: 3 },
  ];
  const cycle = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
  ];
  const filterType = {
    cycle: selectedCycleFilter,
    year: selectedYearFilter,
    'Asset.Network': selectedNetworkFilter,
    'Asset.Region': selectedRegionFilter,
    'Asset.Section': selectedSectionFilter,
    'Asset.Ranking': selectedRankingFilter,
  };
  const source = [
    {
      id: 1, dropdownId: 1, category: 'Pavement', label: 'Patrol', assetId: [52, 53, 54], isPatrol: 1,
    },
    {
      id: 2, dropdownId: 2, category: 'Pavement', label: 'Drone', assetId: [64, 65, 66], isPatrol: 0,
    },
    {
      id: 3, dropdownId: 3, category: 'Traffic Safety', label: 'Patrol', assetId: [52, 55, 56, 57, 58, 59, 60, 61, 62, 63], isPatrol: 1,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    getStaticData();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    refresh();
  }, [tab]);

  useEffect(() => {
    if (!allReports.length) return;
    refresh();
  }, [showDeleted]);

  const refresh = () => {
    setIsLoading(true);
    const data = showDeleted ? { deleted: 1 } : {};
    data.AssetTypeId = tabList.find(t => t.selector == tab).AssetTypeId
    data.reportGroupLabel = tabList.find(t => t.selector == tab).selector
    Api({
      endpoint: endpoints.getReports(),
      data,
      onSuccess: (response) => {
        const report = response.data
          .filter(f => (tab !== 'slope' && tab !== 'bridge') || !!f.Asset)
          .map(e => ({
            ...e,
            'Asset.Network': e.Asset?.NetworkId || e.NetworkId,
            'Asset.Region': e.Asset?.RegionId || e.RegionId,
            'Asset.Section': e.Asset?.SectionId || e.SectionId,
            'Asset.Ranking': e.Asset?.RankingId || undefined,
            year: e.year || new Date(e.createdAt).getFullYear(),
          }))
          .sort((a, b) => b.createdAt.localeCompare(a.createdAt));
        setAllReports(report);
        setFilterReports(report);
        const result = _.uniq(report.map(({ year }) => year)).sort((a, b) => a - b).map(e => ({ label: String(e), value: e }));
        setYear(result);
        setIsLoading(false);
      },
      onFail: (err) => {
        toast('error', 'Failed to fetch report');
        setIsLoading(false);
      },
    });
  };

  const getStaticData = () => {
    Api({
      endpoint: endpoints.getStaticData(),
      onSuccess: ({ data }) => {
        setDetectionType(data.DetectionType.filter((x) => x.AssetTypeId === 3));
        setNetworks(data.Network.map(m => ({ label: m.label.toUpperCase(), value: m.id })));
        setRegions(data.Region.map(m => ({ label: m.name, value: m.id })));
        setSections(data.Section.map(m => ({ label: m.name, value: m.id })));
        setRankings(data.Ranking.map(m => ({ label: m.name, value: m.id })));
      },
      onFail: (err) => { toast('error', 'Failed to fetch static data'); setIsLoading(false); },
    });
  };

  const generateReport = () => {
    const input = {};
    const { selector, AssetTypeId } = tabList.find(t => t.selector == tab);
    input.reportRoadTitle = `Report ${selector}`;
    input.reportGroupLabel = selector;
    input.AssetTypeId = AssetTypeId;
    input.dateRange = { startDate: startGenerateReportDate, endDate: endGenerateReportDate };
    if (generateReportSource) {
      input.SourceId = generateReportSource;
      input.isPatrol = source.find(s => s.dropdownId == generateReportSource).isPatrol;
    }
    if (generateReportNetwork) input.NetworkId = generateReportNetwork;
    if (generateReportSection) input.SectionId = generateReportSection;
    input.DetectionTypeId = (generateReportDetection.length === 0 || generateReportDetection.includes(null)) ? null : generateReportDetection;
    // if (generateReportRegion) input.RegionId = generateReportRegion

    setIsGenerateReportLoading(true);
    Api({
      endpoint: endpoints.createReport(),
      data: { input },
      onSuccess: ({ data }) => {
        console.log('DDD', data);
        setIsGenerateReportLoading(false);
        setOpenGenerateReportDialog(pv => !pv);
        refresh();
        toast('success', 'Successfully Generate Report ');
      },
      onFail: (err) => { toast(err, 'error'); setIsGenerateReportLoading(false); },
    });
  };

  const sortReport = (arr) => {
    return arr.sort((a, b) => (showDeleted ? b.deletedAt.localeCompare(a.deletedAt) : b.createdAt.localeCompare(a.createdAt)));
  };

  useEffect(() => {
    setGenerateReportSource(0);
    setGenerateReportNetwork(0);
    setGenerateReportSection(0);
  }, [tab])

  useEffect(() => {
    if (!allReports.length) return;
    const hasFilterType = (Object.keys(filterType).map(m => filterType[m].length)).filter(f => !!f);
    const selectedFilteredType = [];
    Object.keys(filterType).forEach(m => {
      if (filterType[m].length) selectedFilteredType.push({ [m]: filterType[m] });
    });
    const filteredTypeObj = Object.assign({}, ...selectedFilteredType);
    if (!hasFilterType.length) {
      setFilterReports(sortReport(allReports));
    } else {
      const result = allReports.filter(project => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in filteredTypeObj) {
          if (project[key] === undefined || !filteredTypeObj[key].includes(project[key])) return false;
        }
        return true;
      });
      setFilterReports(sortReport(_.uniq(result.flat())));
    }
  }, [allReports, selectedCycleFilter, selectedYearFilter, selectedNetworkFilter, selectedRegionFilter, selectedSectionFilter, selectedRankingFilter]);

  const downloadMultipleFile = () => {
    selectedRow.forEach(({ image }) => window.open(`${process.env.REACT_APP_FD}/${image}`));
  };

  const onDelete = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.deleteReport(selectedReport.id),
      onSuccess: () => {
        toast('success', 'Successfully Delete ');
        setSelectedReport({});
        refresh();
      },
      onFail: () => {
        setSelectedReport({});
        setIsLoading(false);
        toast('error', 'Failed to Delete ');
      },
    });
  };

  const onRestoreReport = () => {
    setIsLoading(true);
    Api({
      endpoint: endpoints.restoreReport(selectedReport.id),
      onSuccess: () => {
        toast('success', 'Successfully Restore');
        setSelectedReport({});
        refresh();
      },
      onFail: () => {
        setSelectedReport({});
        setIsLoading(false);
        toast('error', 'Failed to Restore ');
      },
    });
  };

  return {
    isLoading,
    refresh,
    allReports,
    generateReport,
    selectedCycleFilter,
    setSelectedCycleFilter,
    selectedYearFilter,
    setSelectedYearFilter,
    cycle,
    yearList,
    selectedRow,
    setSelectedRow,
    filterReports,
    networks,
    sections,
    regions,
    rankings,
    detectionType,
    selectedNetworkFilter,
    setSelectedNetworkFilter,
    selectedRegionFilter,
    setSelectedRegionFilter,
    selectedSectionFilter,
    setSelectedSectionFilter,
    selectedRankingFilter,
    setSelectedRankingFilter,
    downloadMultipleFile,
    onDelete,
    selectedReport,
    setSelectedReport,
    openDialog,
    setOpenDialog,
    showDeleted,
    setShowDeleted,
    onRestoreReport,
    openRestoreDialog,
    setOpenRestoreDialog,
    tab,
    setTab,
    tabList,
    source,
    openGenerateReportDialog,
    setOpenGenerateReportDialog,
    generateReportSource,
    setGenerateReportSource,
    generateReportNetwork,
    setGenerateReportNetwork,
    generateReportSection,
    setGenerateReportSection,
    generateReportDetection,
    setGenerateReportDetection,
    isGenerateReportLoading,
    startGenerateReportDate,
    setStartGenerateReportDate,
    endGenerateReportDate,
    setEndGenerateReportDate,
  };
}
