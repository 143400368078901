import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

const NestedDropdown = ({ title = "", listMenu }) => {
  const [openDropdownMenu, setOpenDropdownMenu] = useState({ 'parent': false });

  const toggleDropdown = (menuKey) => {
    setOpenDropdownMenu((prev) => ({
      ...prev,
      [menuKey]: !prev[menuKey], // Toggle the specific submenu
    }));
  };

  const SubMenu = ({ title, menuKey, submenu, onClick = () => null }) => {
    return (
      <Dropdown isOpen={openDropdownMenu[menuKey] || false} toggle={() => toggleDropdown(menuKey)} direction="right" size='sm'>
        {!!submenu ? (
          <>
            <DropdownToggle caret className='w-50' style={{ minWidth: "150px" }}>{title}</DropdownToggle>
            <DropdownMenu className='dropdown-menu p-0 m-0' style={{ minWidth: "100%" }}>
              {!!submenu.length && submenu.map(f => <DropdownItem className='dropdown-item w-50 pr-2' style={{ minWidth: "150px" }} onClick={f.onClick}>{f.title}</DropdownItem>)}
            </DropdownMenu>
          </>
        ) : (
          <DropdownItem className='dropdown-item w-50 pr-2' style={{ minWidth: "150px" }} onClick={onClick}>{title}</DropdownItem>
        )}
      </Dropdown>
    )
  };

  return (
    <Dropdown isOpen={openDropdownMenu['parent'] || false} toggle={() => toggleDropdown('parent')} direction="right" size='sm'>
      <DropdownToggle caret>{title}</DropdownToggle>
      <DropdownMenu className='dropdown-menu p-0 m-0'>
        {listMenu.map(e => <SubMenu {...e} />)}
      </DropdownMenu>
    </Dropdown>
  )
}

export default NestedDropdown;