import React from 'react';
import CenteredLoadingContainer from '@Components/CenteredLoadingContainer';
import DateTimePickerField from '@Components/DatePicker';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Dropdown from './Dropdown';

const GenerateReportDialog = (h) => {
  const sources = h.source.map(s => ({ label: s.label, value: s.dropdownId }));
  const pavement = [{ label: 'Data Source', value: 0 }, ...sources.filter(s => [1, 2].includes(s.value))];
  const trafficsafety = [{ label: 'Data Source', value: 0 }, ...sources.filter(s => [3].includes(s.value))];
  const selectedSource = [...(h.tab === 'pavement' ? pavement : trafficsafety)].findIndex(d => d.value == h.generateReportSource);
  return (
    <Dialog
      open={h.openGenerateReportDialog}
      onClose={() => h.setOpenGenerateReportDialog(pv => !pv)}
      PaperProps={{
        style: {
          background: 'linear-gradient(90.17deg, #32A9D1 1.24%, #4CD2AE 99.85%)',
          padding: '20px',
          borderRadius: '20px',
          width: '450px',
        },
      }}
    >
      <DialogTitle>
        <h2 style={{ color: 'white', textAlign: 'center', fontWeight: 700 }}>Generate Report</h2>
      </DialogTitle>
      {h.isGenerateReportLoading ? (
        <CenteredLoadingContainer height="50vh" size={75} hasText text="generate report" loadingBarColor="white" textColor="white" />
      ) : (
        <>
          <DialogContent>
            {[
              {
                selected: selectedSource,
                setSelected: (x) => {
                  h.setGenerateReportSource(x);
                  h.setGenerateReportDetection([]);
                },
                item: h.tab === 'pavement' ? pavement : trafficsafety,
              },
              {
                selected: h.generateReportNetwork,
                setSelected: h.setGenerateReportNetwork,
                item: [{ label: 'Network', value: 0 }, ...h.networks.map(x => ({ label: x.label, value: x.value }))],
              },
              {
                selected: h.generateReportSection,
                setSelected: h.setGenerateReportSection,
                item: [{ label: 'Section', value: 0 }, ...h.sections.map(x => ({ label: x.label, value: x.value }))],
              },
              {
                selected: h.generateReportDetection,
                setSelected: h.setGenerateReportDetection,
                multiSelect: true,
                item: [
                  { label: 'Detection', value: [] },
                  { label: 'All', value: null },
                  ...h.detectionType.filter((x) => {
                    if (h.tab === 'pavement') {
                      if (h.generateReportSource === 1) {
                        return [52, 54, 76, 77, 78].includes(x.id);
                      }
                      if (h.generateReportSource === 2) {
                        return [64, 66, 79, 80, 81].includes(x.id);
                      }
                      return [52, 54, 76, 77, 78, 64, 66, 79, 80, 81].includes(x.id);
                    }
                    return [55, 56, 57, 58, 59, 60, 61, 62, 63, 67, 68, 69, 70, 71, 72, 73, 74, 75].includes(x.id);
                  }).map(x => ({ label: x.name, value: x.id })),
                ],
              },
            ].map((x, index) => (
              <div key={index} style={{ marginBottom: '12px' }}>
                <Dropdown
                  multiSelect={x.multiSelect}
                  selectedItem={x.selected}
                  setSelectedItem={x.setSelected}
                  itemList={x.item}
                  tab={h.tab}
                  width="400px"
                  propertyValue="value"
                  Hx="h6"
                  size="small"
                />
              </div>
            ))}
            <div style={{ marginTop: '12px' }}>
              <DateTimePickerField
                label="Start Date"
                value={h.startGenerateReportDate}
                onChange={(e) => h.setStartGenerateReportDate(e.target.value)}
              />
            </div>

            <div style={{ marginTop: '12px' }}>
              <DateTimePickerField
                label="End Date"
                value={h.endGenerateReportDate}
                onChange={(e) => h.setEndGenerateReportDate(e.target.value)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => h.setOpenGenerateReportDialog(pv => !pv)}
              style={{
                backgroundColor: '#C9C8C8', color: 'white', borderRadius: '20px', fontSize: 11, padding: 8, fontWeight: 700,
              }}
            >
              Close
            </Button>
            <Button
              onClick={() => h.generateReport()}
              autoFocus
              style={{
                backgroundColor: '#0061AA', color: 'white', borderRadius: '20px', fontSize: 11, padding: 8, fontWeight: 700,
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default GenerateReportDialog;
